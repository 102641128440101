import React from "react";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { message } from "antd";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.msProvider = new app.auth.OAuthProvider("microsoft.com");
    this.msProvider.setCustomParameters({
      //prompt: "consent",
      //login_hint: "paul.paulaner@paul-consultants.de",
      tenant: process.env.REACT_APP_MICROSOFT_TENANT_ID,
    });

    this.auth = app.auth();
    this.db = app.firestore();
    this.dbFieldValue = app.firestore.FieldValue;
  }

  signIn = () => {
    this.auth
      .signInWithPopup(this.msProvider)
      .then((result) => {
        message.success("Erfolgreich angemeldet!");
      })
      .catch((err) => {
        message.error(`Fehler bei der Anmeldung: ${err}`);
        console.error(err);
      });
  };

  signInAnonymously = async (name) => {
    try {
      await this.auth.signInAnonymously();
      await this.auth.currentUser.updateProfile({ displayName: name });
      message.success("Erfolgreich angemeldet!");
    } catch (err) {
      message.error(`Fehler bei der Anmeldung: ${err}`);
      console.error(err);
    }
  };

  signOut = () => this.auth.signOut();
}

const appFirebase = new Firebase();

const FirebaseContext = React.createContext(null);
const FirebaseContextProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={appFirebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default Firebase;
export { FirebaseContext, FirebaseContextProvider };
