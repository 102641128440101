import { useContext, useState } from "react";
import {
  Typography,
  Row,
  Col,
  Card,
  Button,
  Input,
  message,
  Checkbox,
} from "antd";
import { FirebaseContext } from "../Firebase";

const GiveVote = ({ session, story }) => {
  const firebase = useContext(FirebaseContext);
  const myUid = firebase.auth?.currentUser?.uid;
  const myName = firebase.auth?.currentUser?.displayName || "Unbekannt";

  const [minBt, setMinBt] = useState(1);
  const [estimateRange, setEstimateRange] = useState(false);
  const [maxBt, setMaxBt] = useState(null);

  const myVoteDoc = firebase.db
    .collection(`sessions/${session.id}/stories/${story.id}/votes`)
    .doc(myUid);

  const saveVote = () => {
    if (!minBt) {
      message.error("Gib eine Schätzung für die minimale BT-Zahl ab!");
      return;
    }
    if (!maxBt && estimateRange) {
      message.error("Gib eine Schätzung für die maximale BT-Zahl ab!");
      return;
    }

    myVoteDoc
      .set({ minBt, maxBt, voterName: myName })
      .then(() => message.success("Schätzung gespeichert!"))
      .catch((err) => message.error(`Fehler beim Speichern: ${err}`));
  };

  return (
    <Row gutter={16} style={{ marginBottom: "16px" }}>
      <Card title="Wie lange dauert dieser Teil?" style={{ width: "100%" }}>
        <Row style={{ width: "100%" }} gutter={[16, 16]}>
          <Col span={24}>
            <Checkbox
              defaultChecked={estimateRange}
              onChange={(e) => {
                setEstimateRange(e.target.checked);
                setMaxBt(e.target.checked ? 2 : null);
              }}
            >
              BT-Bereich schätzen
            </Checkbox>
          </Col>
          <Col>
            <Row gutter={[16, 16]} align="middle">
              <Col>
                <Input
                  type="number"
                  defaultValue={minBt}
                  min={0.25}
                  max={100}
                  step={0.25}
                  addonAfter="BT"
                  onChange={(e) => setMinBt(parseFloat(e.target.value))}
                />
              </Col>
              {estimateRange ? (
                <>
                  <Col>
                    <Typography.Text>bis</Typography.Text>
                  </Col>
                  <Col>
                    <Input
                      type="number"
                      defaultValue={maxBt}
                      min={0.25}
                      max={100}
                      step={0.25}
                      addonAfter="BT"
                      onChange={(e) => setMaxBt(parseFloat(e.target.value))}
                    />
                  </Col>
                </>
              ) : null}
              <Col>
                <Button type="primary" onClick={() => saveVote()}>
                  Schätzung abgeben
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default GiveVote;
