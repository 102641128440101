import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FirebaseContext } from "../Firebase";
import Loading from "../Components/Loading";
import StoryList from "../Components/StoryList";
import ShareComponent from "../Components/ShareComponent";

const SessionRun = () => {
  const firebase = useContext(FirebaseContext);
  const { sessionId } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [session, setSession] = useState(null);
  const isAdmin = session?.admin === firebase.auth.currentUser.uid;

  //helper function to set the currently activated story
  const setActiveStory = (storyId) => {
    firebase.db
      .collection("sessions")
      .doc(sessionId)
      .update({
        activeStory: storyId
          ? firebase.db.collection(`sessions/${sessionId}/stories`).doc(storyId)
          : null,
      });
  };

  //observe db updates for session
  useEffect(() => {
    return firebase.db
      .collection("sessions")
      .doc(sessionId)
      .onSnapshot((doc) => {
        setSession({ id: doc.id, ...doc.data() });
        setIsLoading(false);
      });
  }, [firebase.db, sessionId]);

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <ShareComponent {...{ session }} />
      <h1>AE: {session?.name}</h1>
      <StoryList {...{ session, isAdmin, setActiveStory }} />
    </>
  );
};

export default SessionRun;
