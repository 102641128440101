import { useContext, useState, useEffect } from "react";
import "./App.css";
import { Button, Layout, Row, Col, Card, Input } from "antd";
import { WindowsOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { FirebaseContext } from "./Firebase";
import SessionCreate from "./Pages/SessionCreate";
import SessionRun from "./Pages/SessionRun";

const { Content, Footer } = Layout;

function App() {
  const firebase = useContext(FirebaseContext);
  const [authUser, setAuthUser] = useState(null);
  const [anonymousName, setAnonymousName] = useState("");

  useEffect(() =>
    firebase.auth.onAuthStateChanged((user) => setAuthUser(user))
  );

  return (
    <Router>
      <Layout className="layout">
        <Content style={{ padding: "20px" }}>
          {authUser ? (
            <Switch>
              <Route exact path="/" component={SessionCreate} />
              <Route path="/session/:sessionId" component={SessionRun} />
              <Route path="*">
                <h1>Seite nicht gefunden!</h1>
              </Route>
            </Switch>
          ) : (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row>
                  <Card style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Input
                      placeholder="Name"
                      onChange={(e) => setAnonymousName(e.target.value)}
                      value={anonymousName}
                      style={{marginBottom: 16}}
                    />
                    <Button
                      size="large"
                      type="primary"
                      onClick={() => firebase.signInAnonymously(anonymousName)}
                    >
                      Anmelden
                    </Button>
                  </Card>
                </Row>
              </Col>
              <Col span={24}>
                <Row>
                  <Card style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <Button
                      size="large"
                      type="primary"
                      onClick={() => firebase.signIn()}
                    >
                      <WindowsOutlined /> Anmelden (Microsoft-Account)
                    </Button>
                  </Card>
                </Row>
              </Col>
            </Row>
          )}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          V{process.env.REACT_APP_VERSION} | &copy; {new Date().getFullYear()}{" "}
          N. Louas/ P. Kappelt
        </Footer>
      </Layout>
    </Router>
  );
}

export default App;
