import { useEffect, useContext, useState } from "react";
import { Row, Col, Card, Typography, Popover } from "antd";
import { QuestionOutlined } from "@ant-design/icons";
import { FirebaseContext } from "../Firebase";

const VoteCard = ({ id, voterName, minBt, maxBt, revealed }) => {
  return (
    <Col xs={12} sm={6} md={4} lg={3} xl={2}>
      <Card
        bodyStyle={{ padding: "5px", textAlign: "center", height: "120px" }}
      >
        {revealed ? (
          <>
            <Typography.Title
              level={2}
              style={{ marginTop: maxBt ? "0px" : "30px" }}
            >
              {minBt.toLocaleString()}
            </Typography.Title>
            {maxBt ? (
              <>
                <hr />
                <Typography.Title level={2}>
                  {maxBt.toLocaleString()}
                </Typography.Title>
              </>
            ) : null}
          </>
        ) : (
          <Typography.Title level={1}>
            <QuestionOutlined />
          </Typography.Title>
        )}
      </Card>
      <Popover trigger="hover" content={voterName}>
        <Typography.Paragraph strong style={{ textAlign: "center" }}>
          {voterName.toString().split(" ")[0]}
        </Typography.Paragraph>
      </Popover>
    </Col>
  );
};

const Votes = ({ session, story }) => {
  const firebase = useContext(FirebaseContext);
  const [votes, setVotes] = useState([]);

  //observe db updates for votes
  useEffect(() => {
    return firebase.db
      .collection(`sessions/${session.id}/stories/${story.id}/votes`)
      .onSnapshot((res) => {
        setVotes(res.docs.map((_vote) => ({ id: _vote.id, ..._vote.data() })));
      });
  }, [firebase.db, session, story.id]);

  return (
    <Row gutter={16}>
      {votes.map((v) => (
        <VoteCard key={v.id} revealed={story?.votesRevealed} {...v} />
      ))}
    </Row>
  );
};

export default Votes;
