import { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { Row, Col, Card, message, Form, Input, Button, Typography } from "antd";
import { FirebaseContext } from "../Firebase";

const SessionCreate = () => {
  const [form] = Form.useForm();
  const firebase = useContext(FirebaseContext);
  const [redirectToSession, setRedirectToSession] = useState(null);

  const createSession = async ({ sessionName }) => {
    if (!sessionName) {
      message.error("Der AE-Sitzung muss ein Name zugewiesen werden");
      return;
    }
    message.success("Sitzung wird erstellt...");

    const session = await firebase.db.collection("/sessions").doc();
    try {
      await session.set({
        name: sessionName,
        admin: firebase.auth.currentUser?.uid,
      });
      setRedirectToSession(session.id);
    } catch (err) {
      message.error(`Fehler beim Erstellen der Sitzung: ${err}`);
    }
  };

  return (
    <>
      {redirectToSession ? (
        <Redirect to={`/session/${redirectToSession}`} />
      ) : null}
      <Row gutter={[16, 16]}>
        <Col
          xs={{ span: 22, offset: 1 }}
          sm={{ span: 18, offset: 3 }}
          md={{ span: 12, offset: 6 }}
        >
          <Card title="AE-Sitzung starten">
            <Form form={form} onFinish={createSession}>
              <Form.Item
                name="sessionName"
                label="AE-Sitzungs-Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item style={{ float: "right" }}>
                <Button type="primary" htmlType="submit">
                  Los gehts...
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
        <Col xs={{ span: 22, offset: 1 }} sm={{ span: 18, offset: 3 }}>
          <Card title="Hinweise zur Nutzung">
            <Typography.Title level={2}>Wozu dient das Tool?</Typography.Title>
            <Typography.Paragraph>
              Das AE-BT-Poker kann bei PAUL-AEs eingesetzt werden. Gegen Ende
              der AE wird der Aufwand für die einzelnen Projektphasen/
              Arbeitspakete durch die AE-Teilnehmenden geschätzt und diskutiert.
              Der PL entscheidet daraufhin einen Vorschlag für den Aufwand in
              BT, der im Protokoll vermerkt wird. <br /> Durch dieses Tool
              können die AE-Teilnehmenden verdeckt, strukturiert und moderiert
              ihre Schätzung für den Aufwand der einzelnen Phasen abgeben.
              <br />
              Das BT-Poker ist an dem üblichem "Scrum-Poker" orientiert.
            </Typography.Paragraph>
            <Typography.Title level={2}>
              Nutzung durch PL/ AE-Moderator*in
            </Typography.Title>
            <Typography.Paragraph>
              Liebe PLs, liebe AE-Moderierenden, beachtet folgende Punkte bei
              der Nutzung:
              <ul>
                <li>
                  Vor Beginn der AE sollte eine neue Sitzung erstellt werden,
                  nutzt dazu die Funktion auf dieser Seite oben
                </li>
                <li>
                  Teilt den Link, der im Browser angezeigt wird, mit den
                  Teilnehmenden der AE, sodass diese mitmachen können. Die
                  Teilnehmenden müssen sich über ihr PAUL-Konto anmelden (auch
                  für Interessenten freigeschaltet)
                </li>
                <li>
                  Füge auf der Sitzungsseite die einzelnen Arbeitspakete/
                  Projektphasen hinzu
                </li>
                <li>
                  Nur du selber kannst als Moderator*in/ Leitung bei der
                  AE-Sitzung die Arbeitspakete hinzufügen, ein Arbeitspaket
                  aufklappen, die verdeckten Schätzungen aufdecken und
                  Ergebnisse speichern
                </li>
                <li>
                  Die AE-Teilnehmenden sehen dasselbe wie du auch. Du steuerst
                  die Anzeige! Wenn du ein Arbeitspaket aufklappst um
                  Schätzungen anzunehmen wird es auch bei den AE-Teilnehmern
                  aufgeklappt
                </li>
                <li>
                  Einfach ausprobieren! Am besten testest du das Tool kurz vor
                  der AE einfach mit einem anderen Pauli aus
                </li>
              </ul>
            </Typography.Paragraph>
            <Typography.Title level={2}>Probleme und Fragen</Typography.Title>
            <Typography.Paragraph>
              Kontaktiere bei Fragen, Problemen oder Verbesserungsvorschlägen
              gerne <a href="https://teams.microsoft.com/l/chat/0/0?users=peter.kappelt@paul-consultants.de" target="_blank" rel="noreferrer">Peter</a>
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SessionCreate;
