import { useContext, useEffect, useState } from "react";
import { Collapse, Typography, Button, Space, message, Popconfirm } from "antd";
import { DeleteFilled } from "@ant-design/icons";
import { FirebaseContext } from "../Firebase";
import Loading from "./Loading";
import Votes from "./Votes";
import GiveVote from "./GiveVote";
import StoryAdmin from "./StoryAdmin";
import StoryAdd from "./StoryAdd";

const StoryList = ({ session, setActiveStory, isAdmin }) => {
  const firebase = useContext(FirebaseContext);
  const [stories, setStories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //observe db updates for stories
  useEffect(() => {
    return firebase.db
      .collection(`sessions/${session.id}/stories`)
      .orderBy("timestamp")
      .onSnapshot((res) => {
        setStories(
          res.docs.map((_story) => ({ id: _story.id, ..._story.data() }))
        );
        setIsLoading(false);
      });
  }, [firebase.db, session]);

  const deleteStory = (storyId) => {
    firebase.db
      .collection(`sessions/${session.id}/stories`)
      .doc(storyId)
      .delete()
      .catch((err) => message.error(`Fehler beim Löschen: ${err}`));
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      <Collapse
        accordion
        style={{ width: "100%" }}
        activeKey={session?.activeStory?.id}
        onChange={(x) => setActiveStory(x)}
      >
        {stories.map((story) => (
          <Collapse.Panel
            header={<Typography.Text strong>{story.name}</Typography.Text>}
            key={story.id}
            extra={
              <Space>
                {story.estimate ? (
                  <>
                    Schätzung:&nbsp;
                    <Typography.Text code>{story.estimate}</Typography.Text>
                  </>
                ) : null}
                {isAdmin ? (
                  <Popconfirm
                    title="Wirklich löschen?"
                    okText="Ja"
                    cancelText="Abbrechen"
                    onConfirm={() => deleteStory(story.id)}
                  >
                    <Button icon={<DeleteFilled />} danger />
                  </Popconfirm>
                ) : null}
              </Space>
            }
            showArrow={isAdmin}
            collapsible={isAdmin ? undefined : "disabled"}
          >
            {isAdmin ? <StoryAdmin {...{ session, story }} /> : null}
            <GiveVote {...{ session, story }} />
            <Votes {...{ session, story }} />
          </Collapse.Panel>
        ))}
      </Collapse>
      {isAdmin ? <StoryAdd {...{ session }} /> : null}
    </>
  );
};

export default StoryList;
