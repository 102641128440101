import { useState } from "react";
import { Row, Button, Modal, Typography, Alert } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import QRCode from "qrcode.react";

const ShareComponent = ({ session }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <>
      <Modal
        visible={isModalVisible}
        title="Einladung teilen"
        footer={
          <Button type="primary" onClick={() => setIsModalVisible(false)}>
            OK
          </Button>
        }
        onCancel={() => setIsModalVisible(false)}
      >
        <Typography.Paragraph>
          Kopiere den folgenden Text und füge ihn im Teams-Chat ein oder zeige
          den QR-Code!
        </Typography.Paragraph>
        <Alert
          type="info"
          message={
            <>
              <Typography.Paragraph>
                <a href={document.location.href}>An AE-BT-Poker teilnehmen</a> -
                verdeckt über Arbeitsaufwand der Phasen abstimmen.
              </Typography.Paragraph>
            </>
          }
        />
        <Typography.Paragraph style={{ marginTop: "16px", textAlign: "center" }}>
          <QRCode size={350} value={document.location.href} />
        </Typography.Paragraph>
      </Modal>
      <Row style={{ marginBottom: "16px", float: "right" }}>
        <Button
          icon={<ShareAltOutlined />}
          onClick={() => setIsModalVisible(true)}
        >
          Einladung teilen
        </Button>
      </Row>
    </>
  );
};

export default ShareComponent;
