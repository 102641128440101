import { useState, useContext } from "react";
import { Button, Row, Collapse, Input, Space, message, Modal } from "antd";
import { PlusOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { FirebaseContext } from "../Firebase";

const StoryAdd = ({ session }) => {
  const firebase = useContext(FirebaseContext);
  const [addPanelVisible, setAddPanelVisible] = useState(false);
  const [addPanelContent, setAddPanelContent] = useState("");
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [addModalContent, setAddModalContent] = useState("");

  const saveAddPanelContent = () => {
    firebase.db
      .collection(`sessions/${session.id}/stories`)
      .add({
        name: addPanelContent,
        timestamp: firebase.dbFieldValue.serverTimestamp(),
      })
      .then(() => {
        message.success("Arbeitspaket hinzugefügt!");
        setAddPanelVisible(false);
        setAddPanelContent("");
      })
      .catch((err) => message.error(`Fehler beim Hinzufügen: ${err}`));
  };

  const saveAddModalContent = async () => {
    const stories = addModalContent
      .split("\n")
      .filter((x) => x.replace(/\s*/, "").length);
    const batch = firebase.db.batch();

    await stories.map(async (s) => {
      const collectionRef = await firebase.db
        .collection(`sessions/${session.id}/stories`)
        .doc();
      batch.set(collectionRef, {
        name: s,
        timestamp: firebase.dbFieldValue.serverTimestamp(),
      });
    });

    try {
      await batch.commit();
      message.success("Arbeitspakete hinzugefügt!");
      setAddModalVisible(false);
      setAddModalContent("");
    } catch (err) {
      message.error(`Fehler beim Hinzufügen: ${err}`);
    }
  };

  return (
    <>
      <Modal
        title="Arbeitspakete hinzufügen"
        visible={addModalVisible}
        width="80%"
        onCancel={() => {
          setAddModalVisible(false);
          setAddModalContent("");
        }}
        onOk={() => saveAddModalContent()}
      >
        <p>Mehre Arbeitspakete hinzufügen (eins pro Zeile, Leerzeilen werden ignoriert)</p>
        <Input.TextArea
          placeholder="Ein Arbeitspaket je Zeile"
          rows={20}
          value={addModalContent}
          onChange={(e) => setAddModalContent(e.target.value)}
        />
      </Modal>
      {addPanelVisible ? (
        <Collapse style={{ width: "100%" }}>
          <Collapse.Panel
            showArrow={false}
            collapsible="disabled"
            header={
              <Input
                placeholder="Name oder Beschreibung des Arbeitspakets"
                style={{ width: "calc(100% - 86px)" }}
                value={addPanelContent}
                onChange={(e) => setAddPanelContent(e.target.value)}
              />
            }
            extra={
              <Space>
                <Button
                  icon={<CheckOutlined />}
                  type="primary"
                  onClick={() => saveAddPanelContent()}
                />
                <Button
                  icon={<CloseOutlined />}
                  danger
                  onClick={() => {
                    setAddPanelVisible(false);
                    setAddPanelContent("");
                  }}
                />
              </Space>
            }
          />
        </Collapse>
      ) : null}
      <Row style={{ marginTop: "16px", float: "right" }}>
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setAddPanelVisible(true)}
          >
            Arbeitspaket hinzufügen
          </Button>
          <Button
            icon={<PlusOutlined />}
            onClick={() => setAddModalVisible(true)}
          >
            Arbeitspaket hinzufügen (Liste)
          </Button>
        </Space>{" "}
      </Row>
    </>
  );
};

export default StoryAdd;
