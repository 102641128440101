import { useContext, useState, useEffect } from "react";
import { Card, Row, Col, Button, Popconfirm, message, Input } from "antd";
import { FirebaseContext } from "../Firebase";

const StoryAdmin = ({ session, story }) => {
  const firebase = useContext(FirebaseContext);
  const [votes, setVotes] = useState([]);
  const [estimateResult, setEstimateResult] = useState("");

  //observe db updates for votes
  useEffect(() => {
    return firebase.db
      .collection(`sessions/${session.id}/stories/${story.id}/votes`)
      .onSnapshot((res) => {
        setVotes(res.docs.map((_vote) => ({ id: _vote.id, ..._vote.data() })));
      });
  }, [firebase.db, session, story.id]);

  //algorithm for computign a mean of the votes
  const calculateVoteMean = () => {
    const lowest = Math.min(...votes.map((x) => x.minBt));
    const highest = Math.max(
      ...votes.map((x) => (x.maxBt ? x.maxBt : x.minBt))
    );
    const mean =
      votes
        .map((x) => (x.maxBt ? (x.maxBt + x.minBt) / 2 : x.minBt))
        .reduce((x, current) => x + current, 0) / votes.length;

    return { lowest, highest, mean };
  };

  const revealVotes = () => {
    const calculatedMean = calculateVoteMean();
    setEstimateResult(
      `${calculatedMean.mean} BT (Bereich ${calculatedMean.lowest} - ${calculatedMean.highest})`
    );

    firebase.db
      .collection(`sessions/${session.id}/stories`)
      .doc(story.id)
      .update({ votesRevealed: true });
  };

  const resetVotes = async () => {
    const votes = await firebase.db
      .collection(`sessions/${session.id}/stories/${story.id}/votes`)
      .get();
    votes.forEach((v) => v.ref.delete());
    firebase.db
      .collection(`sessions/${session.id}/stories`)
      .doc(story.id)
      .update({ votesRevealed: false });
  };

  const saveEstimateResult = () => {
    if (!estimateResult) {
      message.error(
        "Bitte gib das zu speichernde Ergebnis in das Textfeld ein!"
      );
      return;
    }
    //save estimate
    firebase.db
      .collection(`sessions/${session.id}/stories`)
      .doc(story.id)
      .update({ estimate: estimateResult })
      .then(() => message.success("Ergebnis gespeichert!"))
      .catch((err) => message.error(`Fehler beim Speichern: ${err}`));
    //close active story
    firebase.db
      .collection("sessions")
      .doc(session.id)
      .update({ activeStory: null });
    resetVotes();
  };

  return (
    <Row gutter={16} style={{ marginBottom: "16px" }}>
      <Card title="Steuerung AE-Leiter" style={{ width: "100%" }}>
        <Row style={{ width: "100%" }} gutter={[16, 16]}>
          <Col>
            <Button type="primary" onClick={() => revealVotes()}>
              Schätzungen aufdecken
            </Button>
          </Col>
          <Col>
            <Popconfirm
              title="Alle Schätzungen für diesen Teil werden gelöscht!"
              okText="OK"
              cancelText="Abbrechen"
              onConfirm={() => resetVotes()}
            >
              <Button>Schätzungen zurücksetzen</Button>
            </Popconfirm>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col>
                <Input
                  value={estimateResult}
                  placeholder="Ergebnis eintragen"
                  addonAfter="BT"
                  style={{ width: "250px" }}
                  allowClear={true}
                  onChange={(e) => setEstimateResult(e.target.value)}
                />
              </Col>
              <Col>
                <Button type="primary" onClick={() => saveEstimateResult()}>
                  Ergebnis speichern
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

export default StoryAdmin;
